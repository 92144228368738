import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { MaterialContext } from "../context/MaterialContext";
import Swatch from "../components/Swatch";
import { starOutlineIcon } from "../components/Icons";
//import CollectionControl from "../components/CollectionControl";
//import CollectionPdf from "../components/CollectionPdf";

function Collection() {
  const { allMaterials } = useContext(MaterialContext);
  const { collection, updateCollection } = useContext(UserContext);
  const [checkedCollection, setCheckedCollection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // const [storedCollection, setStoredCollection] = useState(false);
  // const [storedCollectionArray, setStoredCollectionArray] = useState([]);
  //const [storedTitle, setStoredTitle] = useState("");

  const haveCollection = collection.length >= 1 ? true : false;

  useEffect(() => {
    const isExist = allMaterials
      .filter(({ id }) => collection.includes(id))
      .map((item) => item);
    if (isExist.length === collection.length) {
      setCheckedCollection(isExist);
      //setStoredCollection(true);
      setLoaded(true);
    } else {
      if (collection.length >= 1 && collection.length !== isExist.length) {
        const notExist = collection
          .filter((item) => isExist.includes(item))
          .map((item) => item);
        setCheckedCollection(notExist);
        setLoaded(true);
        updateCollection(notExist);
      }
    }
  }, [collection, allMaterials, updateCollection, haveCollection]);

  //const { userId } = userInformation;

  if (loaded & (collection.length >= 1)) {
    return (
      <div className="content">
        <div className="swatch-view">
          {checkedCollection.map((item) => (
            <Swatch key={item.id} id={item.id} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="content">
        <div className="empty-collection">
          <div className="empty-collection-message">
            <div className="text-box">
              <h2>You don't have any materials in your collection.</h2>
              <h4>
                Click on the {starOutlineIcon} to save selected swatches to your
                collection
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // if (!storedCollection) {
  //   return (
  //     <div className="content">
  //       <h2>Your Collection</h2>
  //       <div className="swatch-view">
  //         {storedCollectionArray?.length >= 1 ? (
  //           <>
  //             {storedCollectionArray.map((id) => (
  //               <Swatch key={id} id={id} />
  //             ))}
  //             {/* <CollectionControl
  //               setStoredTitle={setStoredTitle}
  //               storedTitle={storedTitle}
  //               userId={userId}
  //               setCheckedCollection={setCheckedCollection}
  //               collection={collection}
  //               haveCollection={haveCollection}
  //               storedCollection={storedCollection}
  //               setStoredCollection={setStoredCollection}
  //               setStoredCollectionArray={setStoredCollectionArray}
  //             /> */}
  //           </>
  //         ) : (
  //           <div className="empty-collection">
  //             <div className="empty-collection-message">
  //               <div className="text-box">
  //                 <h2>You don't have and selected any materials yet.</h2>
  //                 <h4>
  //                   Click on the {starOutlineIcon} to save selected swatches to
  //                   your collection
  //                 </h4>
  //               </div>
  //               {/* <CollectionControl
  //                 setStoredTitle={setStoredTitle}
  //                 storedTitle={storedTitle}
  //                 userId={userId}
  //                 setCheckedCollection={setCheckedCollection}
  //                 collection={collection}
  //                 haveCollection={haveCollection}
  //                 storedCollection={storedCollection}
  //                 setStoredCollection={setStoredCollection}
  //               /> */}
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div className="content">
  //       <div className="empty-collection">
  //         <div className="empty-collection-message">
  //           <div className="text-box">
  //             <h2>Loading collections...</h2>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default Collection;
