import { useContext, useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import navStyle from "../styles/nav.module.scss";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from "./Icons";
import MultiRangeSlider from "./MultRangeSlider";

function FilterBox({ filterMenuIsVisible, setFilterMenuIsVisible }) {
  const {
    orderBy,
    changeOrderBy,
    changePriceRange,
    status,
    changeStatus,
    changeIsFilter,
    isFilter,
  } = useContext(MaterialContext);
  const [viewing, setViewing] = useState(false);
  const [reset, setReset] = useState(false);

  // useEffect(() => {
  //   if (isFilter) {
  //     changeOrderBy("modified");
  //     setReset(true);
  //     changeStatus("commercial");
  //     setFilterChange(false);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const handleOrder = (value) => {
    changeOrderBy(value);
    //changeIsFilter(true);
  };

  const handleChangeStatus = (value) => {
    changeStatus(value);
    changeIsFilter(true);
  };
  const handleChangePricing = (value) => {
    changePriceRange(value);
    setReset(false);
    changeIsFilter(true);
  };

  const handleClearFilters = () => {
    changeOrderBy("modified");
    setReset(true);
    changeStatus("commercial");
    changeIsFilter(false);
  };

  useEffect(() => {
    const int = setTimeout(() => {
      if (!viewing) {
        setFilterMenuIsVisible(false);
      }
    }, 5000);
    return () => {
      clearInterval(int);
    };
  }, [viewing, setFilterMenuIsVisible]);

  const circLoad = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
      <g>
        <path
          pathLength="90"
          stroke="white"
          d="M15,1c7.7,0,14,6.3,14,14s-6.3,14-14,14S1,22.7,1,15S7.3,1,15,1 M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15
		S23.3,0,15,0L15,0z"
        />
      </g>
    </svg>
  );

  return (
    <div
      className={
        filterMenuIsVisible
          ? `${navStyle.filterBox}`
          : `${navStyle.filterBox} ${navStyle.hidden}`
      }
      onMouseLeave={() => setViewing(false)}
      onMouseEnter={() => setViewing(true)}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        style={{
          background: " #2d3232",
          width: "100px",
          height: "50px",
          position: "absolute",
          right: "-12px",
          top: "-12px",
          borderRadius: "20px",
        }}
      ></div>
      <div
        className={navStyle.closeButton}
        onClick={() => setFilterMenuIsVisible(false)}
      >
        <h4>close</h4> {closeIcon} {circLoad}
      </div>
      <div className={navStyle.content}>
        <div className={navStyle.header}>FILTER MATERIAL ORDER</div>
        <div className={navStyle.arrayFilter}>
          <div
            className={
              orderBy === "modified"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleOrder("modified")}
          >
            updated
          </div>
          <div
            className={
              orderBy === "none"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleOrder("none")}
          >
            Added
          </div>
          <div
            className={
              orderBy === "popular"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleOrder("popular")}
          >
            Popular
          </div>
          <div
            className={
              orderBy === "alphabetical"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleOrder("alphabetical")}
          >
            Alpha #
          </div>
        </div>

        <div className={navStyle.header}>FILTER MATERIAL STATUS</div>
        <div className={navStyle.arrayFilter}>
          <div
            className={
              status === "commercial"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleChangeStatus("commercial")}
          >
            Commercial
          </div>
          <div
            className={
              status === "development"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleChangeStatus("development")}
          >
            Development
          </div>
          <div
            className={
              status === "concept"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleChangeStatus("concept")}
          >
            Concept
          </div>
          <div
            className={
              status === "none"
                ? `${navStyle.button} ${navStyle.active}`
                : `${navStyle.button}`
            }
            onClick={() => handleChangeStatus("none")}
          >
            All
          </div>
        </div>
        <div className={navStyle.header}>FILTER PRICING RANGE</div>
        <MultiRangeSlider
          min={1.8}
          max={2.5}
          reset={reset}
          onChange={({ min, max }) =>
            handleChangePricing([{ min: min, max: max }])
          }
        />
        {isFilter ? (
          <div className={navStyle.reset} onClick={handleClearFilters}>
            Reset Filters
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FilterBox;
