import { useContext } from "react";
import navBarStyle from "../styles/nav.module.scss";
import { MaterialContext } from "../context/MaterialContext";
import { closeIcon } from "./Icons";
import { useNavigate } from "react-router-dom";
function SearchBar() {
  const navigate = useNavigate();
  const { search, changeSearch } = useContext(MaterialContext);
  const handleSearch = (e) => {
    if (navigate === "/library") {
      changeSearch(e.target.value);
    } else {
      navigate("/library");
      changeSearch(e.target.value);
    }
  };
  const handleClear = () => {
    changeSearch("");
  };
  return (
    <div className={navBarStyle.search}>
      <input
        type="text"
        placeholder="search"
        value={search}
        onChange={handleSearch}
      />
      {search && (
        <span className={navBarStyle.clearSearch} onClick={handleClear}>
          {closeIcon}
        </span>
      )}
    </div>
  );
}

export default SearchBar;
