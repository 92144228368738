import React, { useCallback, useEffect, useState, useRef } from "react";

const MultiRangeSlider = ({ min, max, onChange, reset }) => {
  const [minVal, set_minVal] = useState(min);
  const [maxVal, set_maxVal] = useState(max);
  const [changeFilter, setChangeFilter] = useState(false);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  useEffect(() => {
    if (reset) {
      set_minVal(1.8);
      set_maxVal(2.5);
    }
  }, [reset]);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (changeFilter) {
      onChange({ min: minVal, max: maxVal });
    }

    // eslint-disable-next-line
  }, [minVal, maxVal]);

  return (
    <div className={"range-slider"}>
      <input
        type="range"
        min={min}
        max={max}
        step="0.1"
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          setChangeFilter(true);
          const value = Math.min(+event.target.value, maxVal - 0.2);
          set_minVal(value);
          event.target.value = value.toString();
        }}
        className={"thumb thumb--zindex-4"}
      />
      <input
        type="range"
        min={min}
        max={max}
        step="0.1"
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          setChangeFilter(true);
          const value = Math.max(+event.target.value, minVal + 0.2);
          set_maxVal(value);
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">${minVal.toFixed(2)}</div>
        <div className="slider__right-value">${maxVal.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
