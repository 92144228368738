import { useContext, useEffect } from "react";
import { MaterialContext } from "../context/MaterialContext";
import Swatch from "../components/Swatch";

function Library() {
  const {
    materials,
    materialsLoaded,
    search,
    changeSearch,
    status,
    orderBy,
    isFilter,
    changeIsFilter,
  } = useContext(MaterialContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [search, status, orderBy]);

  const handleClearFilterSearch = () => {
    changeIsFilter(false);
    changeSearch("");
  };
  return (
    <div className="content">
      {!materialsLoaded && <div className="loading">Loading</div>}
      <div className="swatch-view">
        {materialsLoaded &&
          materials?.map((material) => (
            <Swatch key={material.id} id={material.id} />
          ))}
        {search && !isFilter && (
          <div className="clear-search" onClick={() => changeSearch("")}>
            <div className="clear-search-button">
              <h3>{materials.length} results for:</h3>
              <h3> "{search}"</h3>
              <h4 className="clear-text">Clear Search</h4>
            </div>
          </div>
        )}
        {isFilter && !search && (
          <div className="clear-search" onClick={() => changeIsFilter(false)}>
            <div className="clear-search-button">
              <h3>{materials.length} results for:</h3>
              <h3> Filter</h3>
              <h4 className="clear-text">Clear Filter</h4>
            </div>
          </div>
        )}

        {isFilter && search && (
          <div className="clear-search" onClick={handleClearFilterSearch}>
            <div className="clear-search-button">
              <h3>{materials.length} results for:</h3>
              <h3> Filter & Search</h3>
              <h4 className="clear-text">Clear All</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Library;
