import { useEffect, useState } from "react";
import FindSimilarFunction from "../utilities/FindSimilarFunction";
import Swatch from "./Swatch";
import singleStyle from "../styles/single.module.scss";

function FilteredLibrary({ id, terms, title, allMaterials, control }) {
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const orderBy = "none";

  useEffect(() => {
    setFilteredMaterials(
      FindSimilarFunction(id, terms, title, allMaterials, orderBy, control)
    );
    // eslint-disable-next-line
  }, [allMaterials, terms, control]);

  return (
    <div className={singleStyle.singleSimilarLibrary}>
      {filteredMaterials?.map((material) => (
        <Swatch key={material.id} id={material.id} />
      ))}
    </div>
  );
}

export default FilteredLibrary;
