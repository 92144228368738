//import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
//import "swiper/css";
import "swiper/swiper.min.css";
import singleStyle from "../styles/single.module.scss";

function SwipeImages({
  swiperRef,
  handleChangeImage,
  materialData,
  vendor,
  slug,
  ROOT_SERVER,
  handleImageLoaded,
  setIsSwiper,
}) {
  return (
    <Swiper
      pagination={true}
      navigation={true}
      ref={swiperRef}
      onSlideChange={(swiper) => {
        handleChangeImage(swiper.activeIndex);
      }}
      onSwiper={() => setIsSwiper(true)}
    >
      {materialData.images.length >= 1
        ? materialData.images.map((item, i) => (
            <SwiperSlide key={i}>
              <img
                alt={item}
                className={singleStyle.materialImage}
                //src={`${ROOT_SERVER}${vendor}/${slug}/full/${item}`}
                src={`${ROOT_SERVER}${vendor}/${slug}/swatch/${item}`}
                srcSet={`${ROOT_SERVER}${vendor}/${slug}/full/${item}`}
                loading="lazy"
                onLoad={handleImageLoaded}
                // style={
                //   imageLoaded
                //     ? { filter: "blur(0px)" }
                //     : { filter: "blur(6px)" }
                // }
              />
            </SwiperSlide>
          ))
        : false}
    </Swiper>
  );
}

export default SwipeImages;
