import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MaterialContext } from "../../context/MaterialContext";
import inputStyle from "../../styles/input.module.scss";
import ImageAdd from "../../components/vendor/ImageAdd";
import DetailAdd from "../../components/vendor/DetailAdd";
import ImageAddNewArticle from "../../components/vendor/ImageAddNewArticle";

function MaterialEdit() {
  const params = useParams();
  const { allMaterials } = useContext(MaterialContext);
  const [materialData, setMaterialData] = useState([]);
  const [materialArray, setMaterialArray] = useState([]);
  const [materialDescription, setMaterialDescription] = useState("");

  useEffect(() => {
    const getMaterialData = allMaterials.filter(
      (mat) => mat.vendor === params.vendor && mat.slug === params.slug
    );
    setMaterialData(getMaterialData[0]);
  }, [allMaterials, params.slug, params.vendor]);

  return (
    <div className="content">
      <div className={inputStyle.splitScreen}>
        <div className={inputStyle.inputData}>
          <DetailAdd
            slug={params.slug}
            vendor={params.vendor}
            materialArray={materialArray}
            setMaterialDescription={setMaterialDescription}
          />
        </div>
        <div className={inputStyle.inputImage}>
          <div className={inputStyle.imagePane}>
            {materialData ? (
              <ImageAdd
                slug={params.slug}
                vendor={params.vendor}
                materialDescription={materialDescription}
                setMaterialArray={setMaterialArray}
              />
            ) : (
              <ImageAddNewArticle
                slug={params.slug}
                vendor={params.vendor}
                materialDescription={materialDescription}
                setMaterialArray={setMaterialArray}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialEdit;
