import fuzzysort from "fuzzysort";

export default function FindSimilarFunction(
  id,
  terms,
  title,
  allMaterials,
  orderBy,
  control
) {
  const optionsFuzzy = {
    limit: 100, // don't return more results than you need!
    allowTypo: true, // if you don't care about allowing typos
    threshold: -100000, // don't return bad results -10000
    keys: [
      "title",
      //"vendor",
      "description",
      //"price",
      "type",
      "subtype",
      "substrate",
      "search",
      "character",
      "feature",
    ],
  };
  let compileResults = [];

  function setCompileResults() {
    const titleSplit = title.split(" ");
    const byTitle = titleSplit.map((item) =>
      fuzzysort.go(item, allMaterials, optionsFuzzy)
    );
    let getTitleResults = byTitle.flat();

    getTitleResults.sort((a, b) => b.score - a.score).map((item) => item.score);

    const convertTitle = getTitleResults.map((item) => item.obj);
    let getTypeResults = fuzzysort.go(control, allMaterials, optionsFuzzy);
    const convertType = getTypeResults
      .map((item) => item.obj)
      .filter((char) => char.character.includes(terms[0]))
      .filter((char) => char.character.includes(terms[1]));
    compileResults = convertTitle.concat(convertType);
  }

  setCompileResults();

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const materialList = compileResults
    .filter((res) => res.id !== id)
    //.map((item) => item.viewCount)
    //.filter((item) => item.vendor === "tectuff")

    //.filter((item) => item.subtype === control)
    //.filter((item) => item.character[0] === item.character)
    //.filter((item) => console.log(item))
    //.reduce((a, b) => b.view_count - a.view_count)
    .filter(onlyUnique);

  return materialList;

  //   const materialSort = materialList
  //     .sort(function (a, b) {
  //       if (orderBy === "none") {
  //         //return new Date(a.date_modified) - new Date(b.date_modified);
  //         return null;
  //       }
  //       if (orderBy === "popular") {
  //         return b.view_count - a.view_count;
  //       }
  //       if (orderBy === "modified") {
  //         return new Date(a.date_modified) - new Date(b.date_modified);
  //       }
  //       if (orderBy === "dateAdded") {
  //         return new Date(a.date_added) - new Date(b.date_added);
  //       }
  //       return a.days_added - b.days_added;
  //     })
  //     .map((res) => res);

  //   return materialSort;
  // }
}
