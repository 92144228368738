import { useContext, useState, useEffect } from "react";
import inputStyle from "../../styles/input.module.scss";
import { MaterialContext } from "../../context/MaterialContext";
import axios from "axios";
import DragDropNewArticle from "./DragDropNewArticle";
import Dropzone from "./Dropzone";
import { closeIcon } from "../../components/Icons";
import PreviewSwatch from "./PreviewSwatch";

function ImageAddNewArticle({
  vendor,
  slug,
  setMaterialArray,
  materialDescription,
}) {
  const { ROOT_SERVER } = useContext(MaterialContext);
  const [images, setImages] = useState([]);
  const [folder, setFolder] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [uploadedImages, setUploadedImages] = useState(false);

  function getData() {
    let folderGet = `${ROOT_SERVER}material/folder/${vendor}/${slug}`;
    axios
      .get(folderGet)
      .then((response) => {
        if (response.data !== "no directory") {
          setUploadedImages(true);
          setFolder(response.data);
        } else {
          setUploadedImages(false);
          setFolder([]);
        }
      })
      .catch((errors) => console.log("there is an error", errors));
  }

  useEffect(() => {
    setMaterialArray(images);
    setUploadedImages(false);
  }, [images, setMaterialArray]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [uploadedImages]);

  const handleAddImageFromFolder = (image) => {
    const newValue = [...images, image];
    setImages(newValue);
  };
  const handleAlreadyInculded = () => {
    alert("its already included");
  };

  const handleDeleteFromFolder = (item) => {
    alert(`ARE YOU SURE YOU WANT TO DELETE ${item}`);
    axios
      .delete(
        `${ROOT_SERVER}upload/delete/${item}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          data: { vendor: vendor, slug: slug },
        }
      )
      .then(() => {
        getData();
      });
  };

  return (
    <>
      <h2>ImageAddNewArticle</h2>
      {folder?.length >= 1 ? (
        <>
          <div className={inputStyle.previewHeader}>
            {images.length >= 1 ? (
              <div className={inputStyle.swatchView}>
                <PreviewSwatch
                  images={images}
                  vendor={vendor}
                  slug={slug}
                  materialDescription={materialDescription}
                />
              </div>
            ) : (
              <div className={inputStyle.previewPlaceHolder}>
                Add images from below to stage
              </div>
            )}
            <div className={inputStyle.selectSwatchView}>
              {previewImage.length !== 0 ? (
                <img
                  className={inputStyle.droppedImage}
                  src={`${ROOT_SERVER}${vendor}/${slug}/swatch/${previewImage}`}
                  alt={"test swatch"}
                />
              ) : (
                <div>Click on any image below to preview</div>
              )}
            </div>
          </div>
          <DragDropNewArticle
            images={images}
            vendor={vendor}
            slug={slug}
            setImages={setImages}
            setMaterialArray={setMaterialArray}
            setPreviewImage={setPreviewImage}
          />
          <div className={inputStyle.inputSwatchContainer}>
            <div className={inputStyle.inputSwatchHeader}>
              <div className={inputStyle.inputSwatchHeaderTitle}>FILES</div>
            </div>
            {folder.length >= 1 &&
              folder?.map((item, i) => (
                <div key={item} className={inputStyle.dragDropItem}>
                  {images.includes(item) ? null : (
                    <div
                      className={inputStyle.deleteDragDropItem}
                      onClick={() => handleDeleteFromFolder(item)}
                    >
                      <div className={inputStyle.deleteIcon}>{closeIcon}</div>
                    </div>
                  )}
                  <img
                    src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${item}`}
                    alt={item + "folder" + i}
                    onClick={() => setPreviewImage(item)}
                  />
                  <div
                    className={inputStyle.folderItemAdd}
                    onClick={
                      !images.includes(item)
                        ? () => handleAddImageFromFolder(item)
                        : handleAlreadyInculded
                    }
                  >
                    {images.includes(item) ? "In View" : "add"}
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : null}
      <div className={inputStyle.inputSwatchContainer}>
        <div className={inputStyle.inputSwatchHeader}>
          <div className={inputStyle.inputSwatchHeaderTitle}>UPLOAD</div>
        </div>
        <Dropzone
          vendor={vendor}
          slug={slug}
          setUploadedImages={setUploadedImages}
        />
      </div>
    </>
  );
}

export default ImageAddNewArticle;
