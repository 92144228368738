import { useState, useEffect, createContext } from "react";
import axios from "axios";
import { ROOT_SERVER, MATERIAL_URL, NIKESTORE } from "../utilities/Static";
import SortFunction from "../utilities/SortFunction";

import { useQuery } from "@tanstack/react-query";

export const MaterialContext = createContext();

const MaterialContextProvider = (props) => {
  const [message, setMessage] = useState("");
  const [materials, setMaterials] = useState([]);
  const [allMaterials, setAllMaterials] = useState([]);
  const [materialsLoaded, setMaterialsLoaded] = useState(false);
  const [nikeData, setNikeData] = useState([]);
  const [search, setSearch] = useState("");
  const [updatedMaterial, setUpdatedMaterial] = useState(false);
  ///Filters
  const [isFilter, setIsFilter] = useState(false);
  const [priceRange, setPriceRange] = useState([{ min: 1.8, max: 2.5 }]);
  const [status, setStatus] = useState("commercial");
  const [orderBy, setOrderBy] = useState("modified");

  const { isLoading, isError, isSuccess, data, refetch } = useQuery({
    queryKey: ["materials"],
    queryFn: () => axios.get(MATERIAL_URL),
  });

  useEffect(() => {
    axios.get(NIKESTORE).then((response) => {
      setNikeData(response.data);
    });
  }, []);

  useEffect(() => {
    if (isError) {
      setMessage("error");
    }
    if (isLoading) {
      setMessage("loading");
    }
    setMaterialsLoaded(false);
    if (isSuccess) {
      setAllMaterials(data.data);
      setMessage("");
      setMaterialsLoaded(true);
    }
  }, [materialsLoaded, updatedMaterial, isLoading, isSuccess, isError, data]);

  useEffect(() => {
    setMaterials(
      SortFunction(search, allMaterials, orderBy, priceRange, status)
    );
    if (search.length >= 1) {
      setMaterials(
        SortFunction(search, allMaterials, orderBy, priceRange, status)
      );
    }
  }, [search, allMaterials, orderBy, priceRange, status]);

  const value = {
    allMaterials,
    materialsLoaded,
    message,
    nikeData,
    ROOT_SERVER,
    materials,
    status,
    refetch,
    changeStatus: (value) => {
      setStatus(value);
    },
    changeMaterials: (arr) => {
      setMaterials(arr);
    },
    search,
    changeSearch: (value) => {
      setSearch(value);
    },
    orderBy,
    changeOrderBy: (value) => {
      setOrderBy(value);
    },
    priceRange,
    changePriceRange: (value) => {
      setPriceRange(value);
    },
    isFilter,
    changeIsFilter: (value) => {
      setIsFilter(value);
    },
    updatedMaterial,
    changeUpdatedMaterial: (value) => {
      setUpdatedMaterial(value);
    },
  };

  return (
    <MaterialContext.Provider value={value}>
      {props.children}
    </MaterialContext.Provider>
  );
};

export default MaterialContextProvider;
