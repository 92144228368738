import { useState } from "react";
import { useNavigate } from "react-router-dom";
import special from "../styles/special.module.scss";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [received, setReceived] = useState(false);

  const navigate = useNavigate();

  const URL_Mail = `https://node.matlib.net/mail/contact`;

  const handleContact = () => {
    fetch(URL_Mail, {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        subject,
        message,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "ok") {
          setReceived(true);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  if (received) {
    return (
      <div className="content">
        <div className={special.contactPage}>
          <div className={special.contactForm}>
            <h1>Message sent</h1>
            <button className="button" onClick={() => navigate("/library")}>
              Go back to library
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content">
      <div className={special.contactPage}>
        <div className={special.contactForm}>
          <h1>Contact</h1>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="name"
          />
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="email"
          />
          <input
            type="text"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            placeholder="subject"
          />
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="message"
          />
          <button className="button" onClick={handleContact}>
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
