import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useParams } from "react-router-dom";

function SpecialLogin({ path }) {
  const { changeUserInformation, userInformation } = useContext(UserContext);
  const guestPath = path.substring(1);
  const params = useParams();

  const pathOptions = ["nike", "lib", "vans"];
  const quickPath = params["*"];

  //const searchParams = useSearchParams();

  const { isLoggedIn } = userInformation;

  useEffect(() => {
    if (!isLoggedIn && pathOptions.includes(quickPath)) {
      handleSpecialUserLogin();
    } else {
      console.log("not logged in yet");
    }
    // eslint-disable-next-line
  }, [isLoggedIn, pathOptions, quickPath]);

  const handleSpecialUserLogin = () => {
    if (!isLoggedIn) {
      changeUserInformation({
        userId: "nike",
        userType: "guest",
        vendor: quickPath,
        userName: quickPath + "guest",
        isLoggedIn: true,
      });
    }
  };

  if (pathOptions.includes(guestPath)) {
    return (
      <div className="content-full">
        <div className="login-view">
          <div className="login-view-content">
            <h2 className="login-view-heading">
              Special Login for {guestPath}
            </h2>
            <button onClick={handleSpecialUserLogin}>Login</button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content-full">
      <div className="login-view">
        <div className="login-view-content">
          <h2 className="login-view-heading">Special Login for {guestPath}</h2>
        </div>
      </div>
    </div>
  );
}

export default SpecialLogin;
