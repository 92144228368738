export default function startDownload(download, vendor, title) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ image: download }),
  };
  fetch("https://node.matlib.net/upload/download", requestOptions)
    .then((response) => response.json())
    .then((data) => imageReceived(data, vendor, title));
}

function imageReceived(image, vendor, slug) {
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  const title = vendor + " - " + slug.replaceAll("-", " ");
  canvas.width = 1024;
  canvas.height = 768;
  var downloadImg = new Image();
  downloadImg.onload = function () {
    context.clearRect(0, 0, 1024, 768);

    context.drawImage(downloadImg, 0, 0, 1024, 768);
    context.font = "bold 16px Montserrat, Helvetica, sans-serif";
    context.fillStyle = "#FEFEFE";
    context.textAlign = "left";
    context.shadowColor = "black";
    context.shadowBlur = 3;
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.fillText(title.toUpperCase(), 10, 20);

    context.font = "bold 16px sans-serif";
    context.fillStyle = "#FEFEFE";
    context.textAlign = "left";

    const matLink = `https://matlib.co/${vendor}/${slug}`;
    var m,
      dx = 0;
    m = context.measureText(matLink);
    dx = m.width + 30;
    context.fillText(matLink, canvas.width - dx, canvas.height - 10);
    let p = new Path2D(
      "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    );

    context.translate(canvas.width - dx - 30, canvas.height - 27);
    context.strokeStyle = "#FEFEFE";
    context.lineWidth = 2;
    context.shadowColor = "black";
    context.shadowBlur = 5;
    context.lineCap = "round";
    context.scale(0.9, 0.9);
    context.stroke(p);

    try {
      var link = document.createElement("a");
      link.download = `${vendor}_${slug}.jpg`;
      link.href = canvas.toDataURL("image/jpeg", 1.0);
      link.click();
    } catch (err) {
      console.log("Error: " + err);
    }
  };
  downloadImg.src = image;
}
