import swatchStyle from "../styles/swatch.module.scss";

function ToolTip({ brandData, setShowToolTip, showToolTip }) {
  return (
    <div
      className={swatchStyle.toolTip}
      onMouseOver={() => setShowToolTip(true)}
      onMouseOut={() => setShowToolTip(false)}
      style={showToolTip ? { left: "0px" } : { left: "-340px" }}
    >
      {brandData.map((item) => (
        <h5 key={item.pcx_material_number}>
          {item.pdm_material_name} PCX#: {item.pcx_material_number}
        </h5>
      ))}
    </div>
  );
}

export default ToolTip;
