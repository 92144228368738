import { useNavigate } from "react-router-dom";
function Footer({ path }) {
  const navigate = useNavigate();

  const Contact = () => {
    if (path !== "/") {
      return (
        <div className="footer-contact" onClick={() => navigate("/contact")}>
          CONTACT
        </div>
      );
    } else {
      return <div className="footer-contact">HELP</div>;
    }
  };

  return (
    <div className="footer-bar">
      <div className="footer-copy">MATLIB 2023</div>
      <Contact />
    </div>
  );
}

export default Footer;
