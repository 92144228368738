import fuzzysort from "fuzzysort";

export default function SortFunction(
  search,
  allMaterials,
  orderBy,
  priceRange,
  status
) {
  const flatSearch = allMaterials
    .map((item) => [
      {
        id: item.id,
        slug: item.slug,
        title: item.title,
        vendor: item.vendor,
        price: item.price,
        description: item.description,
        type: item.type,
        subtype: item.subtype,
        substrate: item.substrate,
        search: item.search,
        status: item.status,
        feature: item.feature.toString(),
        character: item.character.toString(),
        softness: item.softness,
        images: item.images,
        date_added: item.date_added,
        date_modified: item.date_modified,
        view_count: item.view_count,
      },
    ])
    .flat();
  if (search.length >= 1) {
    const optionsFuzzy = {
      limit: 100, // don't return more results than you need!
      allowTypo: true, // if you don't care about allowing typos
      threshold: -10000, // don't return bad results
      keys: [
        "title",
        "vendor",
        "description",
        "price",
        "type",
        "subtype",
        "substrate",
        "search",
        "feature",
        "character",
      ],
    };
    const resultsFuzzy = fuzzysort.go(search, flatSearch, optionsFuzzy);
    const materialList = resultsFuzzy.map((res) => res.obj);

    // const filterStatus = (status) => {
    //   console.log(status);
    // };
    const materialSort = materialList
      .sort(function (a, b) {
        if (orderBy === "none") {
          return new Date(a.date_modified) - new Date(b.date_modified);
        }
        if (orderBy === "popular") {
          return b.view_count - a.view_count;
        }
        if (orderBy === "modified") {
          return new Date(a.date_modified) - new Date(b.date_modified);
        }
        if (orderBy === "dateAdded") {
          return new Date(a.date_added) - new Date(b.date_added);
        }
        return a.days_added - b.days_added;
      })
      .filter((o) => {
        return o.price <= priceRange[0].max && o.price >= priceRange[0].min;
      })
      .map((res) => res);

    return materialSort;
  } else {
    const materialSort = allMaterials
      .sort((a, b) => {
        if (orderBy === "none") {
          return new Date(b.date_added) - new Date(a.date_added);
        }
        if (orderBy === "alphabetical") {
          return a.title.localeCompare(b.title);
        }
        if (orderBy === "popular") {
          return b.view_count - a.view_count;
        }
        if (orderBy === "modified") {
          return new Date(b.date_modified) - new Date(a.date_modified);
        }
        return new Date(b.date_added) - new Date(a.date_added);
      })
      .filter((o) => {
        return o.price <= priceRange[0].max && o.price >= priceRange[0].min;
      })
      .filter((res) => (status !== "none" ? res.status === status : res))
      .map((res) => res);

    return materialSort;
  }
}
