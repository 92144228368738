import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as MaterialDetails from "../../utilities/MaterialDetails";
import { closeTagIcon } from "../../components/Icons";
import { MaterialContext } from "../../context/MaterialContext";
import inputStyle from "../../styles/input.module.scss";
import axios from "axios";

function DetailAdd({ vendor, slug, materialArray, setMaterialDescription }) {
  const { allMaterials, ROOT_SERVER, refetch } = useContext(MaterialContext);
  const navigate = useNavigate();

  const [viewMaterial, setViewMaterial] = useState();
  const [tagInput, setTagInput] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [changed, setChanged] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [id, setId] = useState();

  useEffect(() => {
    const material = allMaterials.find(
      (item) => item.vendor === vendor && item.slug === slug
    );
    if (material && material.id) {
      setViewMaterial(material);
      setLoaded(true);
      setMaterialDescription(material.description);
      setDescriptionLength(material.description.length);
      setId(material.id);
    } else {
      setMaterialDescription([]);
      const { today } = MaterialDetails;
      const materialInitialValue = [
        {
          id: "",
          slug: slug.toLowerCase().replace(/\s/g, "-"),
          vendor: vendor,
          title: slug,
          description: "",
          substrate: "leather",
          type: "cowhide",
          subtype: "",
          character: [],
          feature: [],
          search: "",
          price: "2.25",
          softness: 3,
          status: "concept",
          view_count: 0,
          date_added: today.toLocaleDateString(),
          date_modified: today.toLocaleDateString(),
          images: [],
        },
      ];
      setViewMaterial(materialInitialValue[0]);
      setLoaded(true);
    }
  }, [allMaterials, slug, vendor, setMaterialDescription]);

  const {
    characterArray,
    typeArray,
    subTypeArray,
    featureArray,
    substrateArray,
    statusArray,
  } = MaterialDetails;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      axios
        .put(`${ROOT_SERVER}material/update/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
          data: viewMaterial,
        })
        .then((response) => {
          if (response.status === 200) {
            navigate(-1);
            refetch();
          }
        });
    } else {
      axios
        .post(`${ROOT_SERVER}material/insert`, {
          headers: {
            "Content-Type": "application/json",
          },
          data: viewMaterial,
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/library");
            refetch();
          }
        });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    console.log(viewMaterial);
  };

  const handleTitle = (e) => {
    setChanged(true);
    setViewMaterial({
      ...viewMaterial,
      title: e.target.value.toLowerCase().replaceAll("-", " "),
      slug: e.target.value.toLowerCase().replaceAll(/\s/g, "-"),
    });
  };

  const handleInput = (e) => {
    if (e.target.name === "price") {
      setChanged(true);
      setViewMaterial({
        ...viewMaterial,
        [e.target.name]: e.target.value,
      });
    } else {
      setChanged(true);
      setDescriptionLength(e.target.value.length);
      setMaterialDescription(e.target.value);
      setViewMaterial({
        ...viewMaterial,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelect = (e) => {
    setChanged(true);
    setViewMaterial({ ...viewMaterial, [e.target.name]: e.target.value });
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    setChanged(true);
    if (viewMaterial.search) {
      const searchTerms = viewMaterial.search.split(",");
      const tagArray = [...searchTerms, tagInput];

      setViewMaterial({ ...viewMaterial, search: tagArray.join() });
      setTagInput("");
    } else {
      const tagArray = [tagInput];
      setViewMaterial({ ...viewMaterial, search: tagArray.join() });
      setTagInput("");
    }
  };
  const handleRemoveTag = (tag) => {
    const searchTerms = viewMaterial.search.split(",");
    const removeTag = searchTerms.filter((items) => items !== tag);
    setViewMaterial({ ...viewMaterial, search: removeTag.join() });
    setChanged(true);
  };

  const handleFeatures = (e) => {
    setChanged(true);
    const feature = viewMaterial.feature;
    if (feature?.includes(e.target.value)) {
      var newFeat = feature.filter((feat) => feat !== e.target.value);
      setViewMaterial({ ...viewMaterial, feature: newFeat });
    } else {
      setViewMaterial({
        ...viewMaterial,
        feature: [...viewMaterial.feature, e.target.value],
      });
    }
  };
  const handleCharacter = (e) => {
    setChanged(true);
    const character = viewMaterial.character;
    if (character) {
      if (character?.includes(e.target.value)) {
        var newChar = character.filter((char) => char !== e.target.value);
        setViewMaterial({ ...viewMaterial, character: newChar });
      } else {
        setViewMaterial({
          ...viewMaterial,
          character: [...viewMaterial.character, e.target.value],
        });
      }
    } else {
      setViewMaterial({
        ...viewMaterial,
        character: [e.target.value],
      });
    }
  };

  useEffect(() => {
    if (viewMaterial && viewMaterial.id !== "" && materialArray.length !== 0) {
      if (
        JSON.stringify(viewMaterial.images) !== JSON.stringify(materialArray)
      ) {
        setChanged(true);
        setViewMaterial({ ...viewMaterial, images: materialArray });
      }
    }
    if (viewMaterial && viewMaterial.id === "" && materialArray.length !== 0) {
      if (
        JSON.stringify(viewMaterial.images) !== JSON.stringify(materialArray)
      ) {
        setChanged(true);
        setViewMaterial({ ...viewMaterial, images: materialArray });
      }
    }
  }, [viewMaterial, materialArray]);
  if (loaded && viewMaterial) {
    return (
      <div className={inputStyle.inputDataPane}>
        {id ? (
          <h2>
            Editing: {viewMaterial?.title.toUpperCase().replaceAll("-", " ")}
          </h2>
        ) : (
          <h2>
            Creating: {viewMaterial?.title.toUpperCase().replaceAll("-", " ")}
          </h2>
        )}
        <div className={inputStyle.infoHead}>
          <div>
            {id ? (
              <h4>ID: {id}</h4>
            ) : (
              <h4 style={{ color: "red" }}>UNSAVED MATERIAL</h4>
            )}
            <h4>Slug: {viewMaterial?.slug}</h4>
            <h4>Vendor: {viewMaterial?.vendor.toUpperCase()}</h4>
          </div>
          <div>
            <h4>Status: {viewMaterial?.status}</h4>
            <h4>Added: {viewMaterial?.date_added}</h4>
            <h4>Modified: {viewMaterial?.date_modified}</h4>
          </div>
        </div>
        <hr />
        {loaded && (
          <form onSubmit={handleSubmit}>
            <fieldset>
              <legend>Material Name</legend>
              <input
                type="text"
                value={
                  viewMaterial?.title.toUpperCase().replaceAll("-", " ") || ""
                }
                onChange={handleTitle}
              />
            </fieldset>
            <fieldset style={{ width: "40%" }}>
              <legend>Material Price</legend>
              <div className={inputStyle.priceInput}>
                <h3>$</h3>
                <input
                  type="number"
                  name="price"
                  value={
                    viewMaterial?.price ? parseFloat(viewMaterial.price) : ""
                  }
                  onChange={handleInput}
                />
                <h4>/ sq.ft.</h4>
              </div>
            </fieldset>
            <fieldset>
              <legend>Material Status</legend>
              {statusArray.map((status) => (
                <div key={status} className="checkbox">
                  <input
                    type="radio"
                    id={status}
                    name="status"
                    value={status}
                    //data-status={viewMaterial.status.includes(status)}
                    checked={
                      viewMaterial?.status.includes(status) ? true : false
                    }
                    onChange={handleSelect}
                  />
                  <label htmlFor={status}>{status.toUpperCase()}</label>
                </div>
              ))}
            </fieldset>
            <fieldset>
              <legend>Material Description</legend>
              <textarea
                type="text"
                name="description"
                placeholder="Add Description"
                maxLength="50"
                value={viewMaterial?.description || ""}
                onChange={handleInput}
              />
              <div
                className={inputStyle.descriptionCounter}
                style={{
                  color: descriptionLength >= 45 ? "red" : "grey",
                }}
              >
                <span style={{ width: "100%", textAlign: "right" }}>
                  {descriptionLength}/50
                </span>
              </div>
            </fieldset>

            <fieldset>
              <legend>Material Substrate</legend>
              {substrateArray.map((substrate) => (
                <div key={substrate} className="checkbox">
                  <input
                    type="radio"
                    id={substrate}
                    //data-substrate={viewMaterial.substrate.includes(substrate)}
                    name="substrate"
                    value={substrate}
                    checked={
                      viewMaterial?.substrate.includes(substrate) ? true : false
                    }
                    onChange={handleSelect}
                  />
                  <label htmlFor={substrate}>{substrate.toUpperCase()}</label>
                </div>
              ))}
            </fieldset>

            <fieldset className={inputStyle.searchTagsFieldset}>
              <legend>Search Tags</legend>
              <div className={inputStyle.searchTagsBox}>
                {viewMaterial?.search.length !== 0 && viewMaterial?.search
                  ? viewMaterial?.search.split(",").map((item) => (
                      <div
                        className={inputStyle.searchTags}
                        key={item}
                        onClick={() => handleRemoveTag(item)}
                      >
                        {item} {closeTagIcon}
                      </div>
                    ))
                  : "no tags"}
              </div>
              <div className={inputStyle.searchTagsInputBox}>
                <input
                  type="text"
                  name="search"
                  maxLength="50"
                  value={tagInput}
                  placeholder="Add search term"
                  onChange={(e) => setTagInput(e.target.value)}
                />
                <button
                  onClick={handleAddTag}
                  className="search-tag-input-button"
                >
                  Add Tag
                </button>
              </div>
            </fieldset>
            <fieldset>
              <legend>Material Type</legend>
              {typeArray.map((type) => (
                <div key={type} className="checkbox">
                  <input
                    type="radio"
                    id={type}
                    name="type"
                    value={type}
                    checked={viewMaterial?.type.includes(type) ? true : false}
                    onChange={handleSelect}
                  />
                  <label htmlFor={type}>{type.toUpperCase()}</label>
                </div>
              ))}
            </fieldset>
            <fieldset>
              <legend>Material Sub-Type</legend>

              {viewMaterial?.type !== ""
                ? subTypeArray[0][viewMaterial?.type].map((subtype) => (
                    <div key={subtype} className="checkbox">
                      <input
                        type="radio"
                        id={subtype}
                        name="subtype"
                        value={subtype}
                        checked={
                          viewMaterial?.subtype.includes(subtype) ? true : false
                        }
                        onChange={handleSelect}
                      />
                      <label htmlFor={subtype}>{subtype.toUpperCase()}</label>
                    </div>
                  ))
                : "select type first"}
            </fieldset>
            <fieldset className="checkbox-array">
              <legend>Character</legend>
              {characterArray.map((char) => (
                <div key={char} className="checkbox">
                  <input
                    type="checkbox"
                    id={char}
                    name={char}
                    value={char}
                    checked={
                      viewMaterial?.character.includes(char) ? true : false
                    }
                    onChange={handleCharacter}
                  />
                  <label htmlFor={char}>{char.toUpperCase()}</label>
                </div>
              ))}
              {viewMaterial.character.map((char) => (
                <p key={char}>{char}</p>
              ))}
              <div
                onClick={() =>
                  setViewMaterial({ ...viewMaterial, character: [] })
                }
              >
                Clear
              </div>
            </fieldset>
            <fieldset className="checkbox-array">
              <legend>Finish</legend>
              {featureArray.map((feat) => (
                <div key={feat} className="checkbox">
                  <input
                    type="checkbox"
                    id={feat}
                    name={feat}
                    value={feat}
                    checked={
                      viewMaterial?.feature.includes(feat) ? true : false
                    }
                    onChange={handleFeatures}
                  />
                  <label htmlFor={feat}>{feat.toUpperCase()}</label>
                </div>
              ))}
              {viewMaterial.feature.map((feat) => (
                <p key={feat}>{feat}</p>
              ))}
              <div
                onClick={() =>
                  setViewMaterial({ ...viewMaterial, feature: [] })
                }
              >
                Clear
              </div>
            </fieldset>
            {changed && (
              <div className="submit-changes">
                <button className="basic-button" type="submit">
                  Submit
                </button>
                <button className="basic-button" onClick={handleReset}>
                  Reset
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    );
  } else {
    return <div>Something Broke</div>;
  }
}

export default DetailAdd;
