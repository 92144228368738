export const today = new Date();

export const characterArray = [
  "soft",
  "medium",
  "firm",
  "aniline",
  "semi-aniline",
  "pigment",
];
export const finishArray = ["aniline", "semi-aniline", "pigment"];
export const featureArray = [
  "perforation",
  "antique",
  "tumbled",
  "glazed",
  "two-tone",
  "distressed",
  "patent",
  "matte",
  "metallic",
  "embossed",
  "fracture",
  "waxy",
  "oily",
  "pull-up",
  "brush-off",
  "textured",
  "hair-cell",
];
export const availabilityArray = [
  "waterproof",
  "non-wicking",
  "metallic",
  "multi substrate",
  "color fast",
  "custom design",
];
export const texturedArray = [
  "natural",
  "exotic",
  "silk",
  "industrial",
  "woven",
  "3D custom",
];
export const sustainablityArray = ["kind", "common", "neutral", "low waste"];
export const typeArray = ["cowhide", "split", "goat", "synthetic"];
export const subTypeArray = [
  {
    cowhide: ["full-grain", "corrected", "nubuck", "flesh-out"],
    split: ["suede", "finished", "coated"],
    goat: ["finished"],
    synthetic: ["microfiber", "textile", "TPU"],
  },
];
export const statusArray = ["commercial", "development", "concept", "hidden"];
export const substrateArray = ["leather", "synthetic", "multi"];
