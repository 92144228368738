import React, { useState, useContext } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { closeIcon } from "../Icons";

import inputStyle from "../../styles/input.module.scss";
import { MaterialContext } from "../../context/MaterialContext";

function Dropzone({ vendor, slug, setUploadedImages }) {
  const { ROOT_SERVER } = useContext(MaterialContext);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleDeleteFromUpload = (item) => {
    const removeItemFromArray = files.filter((file) => file !== item);
    setFiles(removeItemFromArray);
  };

  const handleSubmit = () => {
    setUploading(true);
    if (files.length) {
      const formData = new FormData();
      formData.append("vendor", vendor);
      formData.append("slug", slug);
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      axios
        .post(`${ROOT_SERVER}upload/images`, formData)
        .then((res) => {
          if (res.status === 200) {
            setUploadedImages(true);
            setFiles([]);
            setUploading(true);
          }
        })
        .catch((errors) =>
          console.log("there is an error in dropzone", errors)
        );
    }
  };

  return (
    <>
      <div
        className={
          files.length ? inputStyle.dropZone : inputStyle.dropZoneExpanded
        }
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? <p>Here ...</p> : <p>Drop Images</p>}
      </div>
      <div className={inputStyle.dropDeck}>
        {files.length
          ? files.map((preview, i) => (
              <div key={preview.name + i} className={inputStyle.dragDropItem}>
                <div
                  className={inputStyle.deleteDragDropItem}
                  onClick={() => handleDeleteFromUpload(preview)}
                >
                  <div className={inputStyle.deleteIcon}>{closeIcon}</div>
                </div>
                <img
                  src={preview.preview}
                  alt={preview.name}
                  width="100"
                  height="100"
                />
              </div>
            ))
          : null}
      </div>
      {files.length ? (
        <div className={inputStyle.dropZoneUpload} onClick={handleSubmit}>
          {uploading ? "uploading" : "submit"}
        </div>
      ) : null}
    </>
  );
}
export default Dropzone;
