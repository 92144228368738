import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./fonts/Montserrat/Montserrat-Regular.ttf";
import "./fonts/Montserrat/Montserrat-Bold.ttf";

// 👇️ make sure to use the correct root element ID
// from your public/index.html file
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
