import { useMediaQuery } from "react-responsive";
export default function MediaQuery() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  //const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  //const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  //const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  if (isMobile) {
    return "mobile";
  }
  if (isDesktop) {
    return "laptop";
  }
  if (isTablet) {
    return "tablet";
  }

  return "dont know";
}
