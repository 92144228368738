import axios from "axios";
import { ROOT_SERVER } from "../utilities/Static";
export default function MaterialViewCount(id) {
  const localAdded = localStorage.getItem("viewedMats");
  if (!localAdded) {
    localStorage.setItem("viewedMats", "[]");
  } else {
    const alreadyViewed = JSON.parse(localAdded).includes(id);
    if (id && !alreadyViewed) {
      const existing = JSON.parse(localAdded);
      axios
        .put(`${ROOT_SERVER}material/view_count/${id}/add`)
        .then(
          localStorage.setItem("viewedMats", JSON.stringify([...existing, id]))
        );
    }
  }
}
