//import { useContext } from "react";
//import { UserContext } from "../context/UserContext";
import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";

function UnrotectedRoutes() {
  //const { userInformation } = useContext(UserContext);
  //let isLoggedIn = userInformation.isLoggedIn;

  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}

export default UnrotectedRoutes;
