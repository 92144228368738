import { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazy-load";
//import CollectionButton from "../CollectionButton";
import { MaterialContext } from "../../context/MaterialContext";

import swatchStyle from "../../styles/swatch.module.scss";

//import MaterialModal from "../MaterialModal";

function PreviewSwatch({ images, vendor, slug, materialDescription }) {
  const { ROOT_SERVER } = useContext(MaterialContext);
  const [viewing, setViewing] = useState("");
  const [imageNumber, setImageNumber] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (images.length !== 0) {
      setViewing(
        `${ROOT_SERVER}${vendor}/${slug}/swatch/${images[imageNumber]}`
      );
    }
  }, [imageNumber, images, ROOT_SERVER, slug, vendor]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className={swatchStyle.swatch}>
        <LazyLoad offset={200} threshold={0.25}>
          <img src={viewing} alt={slug} onLoad={handleImageLoaded} />
        </LazyLoad>
        <div className={swatchStyle.vendor}>
          <p>{vendor}</p>
        </div>

        {images?.length >= 2 && (
          <div className={swatchStyle.imageSelector}>
            {images?.map((image, i) => (
              <span
                key={image}
                onClick={() => setImageNumber(i)}
                style={
                  imageNumber === i
                    ? { background: "transparent" }
                    : { background: "#FEFEFE90" }
                }
              />
            ))}
          </div>
        )}
        {!imageLoaded && <div className={swatchStyle.loadingBar} />}
        <div className={swatchStyle.information}>
          <h3>{slug.toUpperCase().replaceAll("-", " ")}</h3>
          <h4>{materialDescription}</h4>
        </div>
      </div>
    </>
  );
}

export default PreviewSwatch;
