import { useEffect, useState, useRef, useContext } from "react";

import "swiper/swiper.min.css";
import { MaterialContext } from "../context/MaterialContext";
import { UserContext } from "../context/UserContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import singleStyle from "../styles/single.module.scss";
import FilteredLibrary from "../components/FilteredLibrary";
import MaterialViewCount from "../utilities/MaterialViewCount";
import ImageDownload from "../utilities/ImageDownload";
import SwipeImages from "../components/SwipeImages";

function SingleMaterial() {
  const { allMaterials, ROOT_SERVER } = useContext(MaterialContext);
  const { userInformation } = useContext(UserContext);
  const [materialData, setMaterialData] = useState([]);
  const [materialDataLoaded, setMaterialDataLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [imageDownload, setImageDownload] = useState(false);
  const [viewing, setViewing] = useState("");
  const [isSwiper, setIsSwiper] = useState(false);

  const { vendor, slug } = useParams();
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  useEffect(() => {
    const getMaterialData = allMaterials.filter(
      (mat) => mat.vendor === vendor && mat.slug === slug
    );
    if (getMaterialData.length === 1) {
      setMaterialData(getMaterialData[0]);
      if (isSwiper) {
        swiperRef.current.swiper.slideTo(0);
      }
      setMaterialDataLoaded(true);
      setImageNumber(0);
    } else {
      setMaterialDataLoaded(false);
    }
    // eslint-disable-next-line
  }, [vendor, slug, allMaterials]);

  useEffect(() => {
    MaterialViewCount(materialData.id);
  }, [materialData.id]);

  useEffect(() => {
    if (materialDataLoaded) {
      setLoaded(true);
      setImageLoaded(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      // if (isSwiper) {
      //   swiperRef.current.swiper.slideTo(1);
      // }
    }
  }, [materialDataLoaded, imageNumber, slug, vendor, loaded]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
    setViewing(
      `${ROOT_SERVER}${vendor}/${slug}/full/${materialData.images[imageNumber]}`
    );
  };
  const handleChangeImage = (i) => {
    if (i !== imageNumber) {
      setImageLoaded(false);
      setImageNumber(i);
      if (isSwiper) {
        swiperRef.current.swiper.slideTo(i);
      }
    }
  };

  const handleEdit = (vendor, slug) => {
    navigate(`../material/edit/${vendor}/${slug}`);
  };

  const handleDownloadImage = (vendor, slug) => {
    ImageDownload(viewing, vendor, slug);
    setImageDownload(true);
    setTimeout(() => {
      setImageDownload(false);
    }, 3000);
  };

  if (loaded) {
    const {
      id,
      title,
      slug,
      vendor,
      description,
      price,
      status,
      type,
      subtype,
      softness,
      substrate,
      character,
      feature,
    } = materialData;

    return (
      <div className="content">
        <div className={singleStyle.singleContent}>
          {/* IMAGE CONTAINER START */}
          <div className={singleStyle.imageContainer}>
            {!imageLoaded && <div className="spinner" />}
            <SwipeImages
              swiperRef={swiperRef}
              handleChangeImage={handleChangeImage}
              materialData={materialData}
              vendor={vendor}
              slug={slug}
              ROOT_SERVER={ROOT_SERVER}
              handleImageLoaded={handleImageLoaded}
              setIsSwiper={setIsSwiper}
            />

            <div className={singleStyle.imageSelector}>
              {materialData.images.length >= 2 &&
                materialData.images.map((image, i) => (
                  <div
                    key={image + i}
                    className={`${singleStyle.swatch} ${
                      i === imageNumber && singleStyle.active
                    }`}
                    onClick={() => handleChangeImage(i)}
                  >
                    <img
                      src={`${ROOT_SERVER}${vendor}/${slug}/thumbnail/${materialData.images[i]}`}
                      alt={image + i}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* IMAGE CONTAINER END */}
          <div className={singleStyle.dataContainer}>
            <div className={singleStyle.dataContainerTop}>
              <h3 style={{ textTransform: "uppercase" }}>{vendor}</h3>
              <h1>{title}</h1>
              <h3>{description}</h3>
              {/* INFO BOX START >> PRICE & MATERIAL DATA */}
              <div className={singleStyle.infoBox}>
                <div className={singleStyle.infoContent}>
                  <div className={singleStyle.infoContentHeader}>
                    <h4>Price</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    <div className={singleStyle.price}>
                      <div className={singleStyle.value}>
                        ${parseFloat(price).toFixed(2)}
                      </div>
                      <div className={singleStyle.uom}>/ sq.ft.</div>
                      <span className={singleStyle.disclaimer}>
                        Price for reference only.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={singleStyle.infoContent}>
                  <div className={singleStyle.infoContentHeader}>
                    <h4>Material Data</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    <h4>Status: {status}</h4>
                    <h4>Type: {substrate}</h4>
                    <h4>Subtype: {type}</h4>
                    <h4>Group: {subtype}</h4>
                  </div>
                </div>
              </div>
              {/* INFO BOX START >> Character & Finish */}
              <div className={singleStyle.infoBox}>
                <div className={singleStyle.infoContent}>
                  <div className={singleStyle.infoContentHeader}>
                    <h4>CHARACTER</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    <h4>{character[0]} temper</h4>
                    <h4>{character[1]}</h4>
                  </div>
                </div>
                <div className={singleStyle.infoContent}>
                  <div className={singleStyle.infoContentHeader}>
                    <h4>Finish</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    {feature?.map((fin) => (
                      <h4 key={fin}>{fin}</h4>
                    ))}
                  </div>
                </div>
              </div>
              {/* END CHARACTER & FINISH */}
              {/* INFO BOX START >> softness */}
              <div className={singleStyle.infoBox}>
                <div
                  style={{ width: "100%" }}
                  className={singleStyle.infoContent}
                >
                  <div className={singleStyle.infoContentHeader}>
                    <h4>softness {softness}</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    <span
                      style={{
                        width: `calc((${softness}/5) * 100% )`,
                        height: "5px",
                        backgroundColor: "#00ffed",
                        display: "flex",
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              {/* END SOFTNESS */}
              {/* INFO BOX START >> SUSTAINABILITY */}
              <div className={singleStyle.infoBox}>
                <div
                  style={{ width: "100%" }}
                  className={singleStyle.infoContent}
                >
                  <div className={singleStyle.infoContentHeader}>
                    <h4>SUSTAINABILIY</h4>
                  </div>
                  <div className={singleStyle.infoContentBody}>
                    <h4>Asiatan Kind Leather</h4>
                    <h4>Carbon Neutral Athletic Leather</h4>
                    <h4>Common Crust Program</h4>
                    <h4>90% Cutting Value for Reduced waste</h4>
                  </div>
                </div>
              </div>
              {/* END SUSTAINABILITY */}
              {/* Start Edit data */}
              {userInformation.userType === "vendor" ? (
                <div className={singleStyle.infoBox}>
                  <div
                    style={{ width: "100%" }}
                    className={singleStyle.infoContent}
                  >
                    <div className={singleStyle.infoContentBody}>
                      <h4
                        className={singleStyle.pageLink}
                        onClick={() => handleEdit(vendor, slug)}
                      >
                        Edit {title}
                      </h4>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* End Edit data */}
            </div>
            {/* Start data container bottom */}
            <div className={singleStyle.dataContainerBottom}>
              {!imageDownload ? (
                <div
                  className={singleStyle.bottomButton}
                  onClick={() => handleDownloadImage(vendor, slug)}
                >
                  <h4>Download Image</h4>
                </div>
              ) : (
                <div className={singleStyle.bottomButton}>
                  <h4>Downloading</h4>
                </div>
              )}
            </div>
            {/* End data container bottom */}
          </div>
        </div>
        <div className={singleStyle.singleSimilar}>
          <FilteredLibrary
            id={id}
            terms={character}
            title={title}
            allMaterials={allMaterials}
            control={subtype.toLowerCase()}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="content">
      <div className="loaderUp">
        <h3>{vendor.replaceAll("-", " ").toUpperCase()}</h3>
        <h2>{slug.replaceAll("-", " ").toUpperCase()}</h2>
        <div className="spinner" />
      </div>
    </div>
  );
}

export default SingleMaterial;
