import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { starFillIcon, starOutlineIcon } from "./Icons";

function CollectionButton({ materialId }) {
  const { collection, changeCollection } = useContext(UserContext);

  const handleChange = () => {
    changeCollection(materialId);
  };

  return (
    <div onClick={() => handleChange(materialId)}>
      {collection.includes(materialId) ? starFillIcon : starOutlineIcon}
    </div>
  );
}

export default CollectionButton;
