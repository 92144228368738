export const starOutlineIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 25 25"
    fill="currentColor"
  >
    <path
      d="M23.8,8L17,7l-3.1-6.2c-0.5-1.1-2.1-1.1-2.7,0L8.1,7L1.3,8c-1.2,0.2-1.7,1.7-0.8,2.6l5,4.8l-1.2,6.8
	c-0.2,1.2,1.1,2.2,2.2,1.6l6.1-3.2l6.1,3.2c1.1,0.6,2.4-0.3,2.2-1.6l-1.2-6.8l5-4.8C25.5,9.7,25,8.2,23.8,8L23.8,8z M17.3,14.6
	l1.1,6.5l-5.8-3.1l-5.8,3.1l1.1-6.5L3.1,10l6.5-0.9l2.9-5.9l2.9,5.9L22,10L17.3,14.6L17.3,14.6z"
    />
  </svg>
);

export const starFillIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 25 25"
    fill="currentColor"
  >
    <path
      d="M23.8,8L17,7l-3.1-6.2c-0.5-1.1-2.1-1.1-2.7,0L8.1,7L1.3,8c-1.2,0.2-1.7,1.7-0.8,2.6l5,4.8l-1.2,6.8
	c-0.2,1.2,1.1,2.2,2.2,1.6l6.1-3.2l6.1,3.2c1.1,0.6,2.4-0.3,2.2-1.6l-1.2-6.8l5-4.8C25.5,9.7,25,8.2,23.8,8L23.8,8z"
    />
  </svg>
);
export const nikeLogo = (
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <g>
      <path
        d="M22.7,8.1c-0.5,0.2-1,0.4-1.4,0.6c-1,0.4-1.9,0.8-2.9,1.2c-1.2,0.5-2.4,1-3.6,1.6c-1.4,0.6-2.9,1.2-4.3,1.8
		C9,14,7.5,14.7,6,15.3c-0.7,0.3-1.4,0.5-2.1,0.6c-0.8,0.1-1.5,0.1-2.2-0.4c-0.7-0.4-1-1-1.1-1.8c0-1,0.3-1.9,0.8-2.7
		C2,9.9,2.8,9,3.6,8.1c0,0,0,0,0.1-0.1C3.6,8.3,3.5,8.5,3.4,8.7C3.1,9.4,2.9,10.1,3,10.9c0.2,0.9,0.8,1.4,1.7,1.6
		c0.7,0.1,1.4,0,2.1-0.2c2.2-0.6,4.4-1.2,6.5-1.7c1.2-0.3,2.4-0.6,3.6-1C17.9,9.3,19,9,20.1,8.7C20.9,8.5,21.8,8.3,22.7,8.1
		C22.7,8,22.7,8,22.7,8.1C22.7,8.1,22.7,8.1,22.7,8.1z"
      />
    </g>
  </svg>
);

export const downCircleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
    />
  </svg>
);

export const downArrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="currentColor"
    viewBox="0 0 20 20"
    stroke="none"
  >
    <path d="M9.6,15.8l-9.5-11C-0.2,4.5,0.1,4,0.5,4h18.9c0.5,0,0.7,0.5,0.4,0.8l-9.5,11C10.2,16.1,9.8,16.1,9.6,15.8z" />
  </svg>
);

export const closeTagIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clipRule="evenodd"
    />
  </svg>
);

export const checkCircleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const downloadIcon = (
  <div className="download-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

export const pdfIcon = (
  <div className="pdf-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
      />
    </svg>
  </div>
);

export const closeIcon = (
  <div className="close-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
);

export const designerIcon = (
  <div className="role-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      stroke="none"
    >
      <g>
        <g>
          <path
            d="M20.7,9.4c1.5,1.3,2.2,3.2,2.2,5.6s-0.7,4.2-2.1,5.6c-1.4,1.4-3.6,2.1-6.5,2.1H9.1V7.4h5.2C17.1,7.4,19.2,8,20.7,9.4z
			 M20.3,15c0-3.5-2-5.2-6-5.2h-2.6v10.5h2.9c1.8,0,3.3-0.4,4.2-1.3C19.8,18,20.3,16.7,20.3,15z"
          />
        </g>
        <g>
          <path
            d="M15,2.2c7.1,0,12.8,5.7,12.8,12.8S22.1,27.8,15,27.8S2.2,22.1,2.2,15S7.9,2.2,15,2.2 M15,0.2C6.8,0.2,0.2,6.8,0.2,15
			S6.8,29.8,15,29.8S29.8,23.2,29.8,15S23.2,0.2,15,0.2L15,0.2z"
          />
        </g>
      </g>
    </svg>
  </div>
);

export const vendorIcon = (
  <div className="role-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      stroke="none"
    >
      <g>
        <g>
          <path d="M16.4,22.7h-2.9L7.4,7.3h2.9L15,18.8l4.7-11.4h2.9L16.4,22.7z" />
        </g>
        <g>
          <path
            d="M15,2.2c7.1,0,12.8,5.7,12.8,12.8S22.1,27.8,15,27.8S2.2,22.1,2.2,15S7.9,2.2,15,2.2 M15,0.2C6.8,0.2,0.2,6.8,0.2,15
			S6.8,29.8,15,29.8S29.8,23.2,29.8,15S23.2,0.2,15,0.2L15,0.2z"
          />
        </g>
      </g>
    </svg>
  </div>
);

export const guestIcon = (
  <div className="role-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      stroke="none"
    >
      <g>
        <g>
          <path
            d="M15,2.2c7.1,0,12.8,5.7,12.8,12.8S22.1,27.8,15,27.8S2.2,22.1,2.2,15S7.9,2.2,15,2.2 M15,0.2C6.8,0.2,0.2,6.8,0.2,15
			S6.8,29.8,15,29.8S29.8,23.2,29.8,15S23.2,0.2,15,0.2L15,0.2z"
          />
        </g>
        <path
          d="M16.1,15v3h2.7v1.9c-1.1,0.7-2.4,1-4,1c-1.6,0-2.9-0.5-4-1.6c-1.1-1.1-1.7-2.5-1.7-4.2c0-1.8,0.6-3.2,1.7-4.3
		c1.1-1.1,2.5-1.6,4.2-1.6c0.9,0,1.6,0.1,2.3,0.3c0.7,0.2,1.3,0.6,2,1.2l1.5-2.1c-1.6-1.4-3.6-2.1-6-2.1c-2.4,0-4.4,0.8-6.1,2.5
		c-1.7,1.6-2.5,3.7-2.5,6.2c0,2.5,0.8,4.5,2.5,6.1c1.7,1.6,3.7,2.4,6.2,2.4c2.9,0,5.1-0.9,6.7-2.6v-3v-2.7V15H16.1z"
        />
      </g>
    </svg>
  </div>
);

export const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    width="24px"
    height="24px"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
    />
  </svg>
);

export const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    width="24px"
    height="24px"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
    />
  </svg>
);
